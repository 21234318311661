import { useEffect } from 'react';
import { deviceSlice } from '../common/api/apiSlice';
import { useAppDispatch, useAppSelector } from '../rootStore';

const DeviceClass: React.FC = () => {
  const dispatch = useAppDispatch();
  const { device } = useAppSelector((state) => state.device);

  const cl = document.documentElement.classList;

  useEffect(() => {
    window.onresize = () => {
      dispatch(deviceSlice.actions.setDevice());
    };
  }, []);

  useEffect(() => {
    if (cl.contains('device-override')) {
      return;
    }

    if (device === 'desktop') {
      cl.add('device-desktop');
      cl.remove('device-tablet');
      cl.remove('device-phone');
    } else if (device === 'tablet') {
      cl.remove('device-desktop');
      cl.add('device-tablet');
      cl.remove('device-phone');
    } else if (device === 'phone') {
      cl.remove('device-desktop');
      cl.remove('device-tablet');
      cl.add('device-phone');
    }
  }, [device]);

  return null;
};

export default DeviceClass;
