import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { sectionObject, getActionComponentFromString } from '../Utils';
import { createStore } from '../rootStore';
import { setPageData } from '../common/api/apiSlice';

function Sector({ sector }) {
  const store = createStore();
  const dispatch = useDispatch();
  const {
    pageData: {
      precompiled_html: precompiledHTML,
      master_page: masterPage,
      page_version: pageVersion,
      page,
    },
  } = store.getState();
  const allSections = useSelector((state) => state.pageData.section);
  const sections = allSections.filter((s) => s.sector === sector.label && !s.column_section);

  if (precompiledHTML && precompiledHTML[sector.label]) {
    return (
      <div
        className="precompiled-html"
        dangerouslySetInnerHTML={{ __html: precompiledHTML[sector.label] }}
      />
    );
  }

  const sectionComponentFromSectionObject = (section) => (
    sectionObject(page, section, (e) => dispatch(setPageData({ clicked_section: e })))
  );

  let Action;
  const params = new URL(document.location).searchParams;
  if (params.get('Action')) {
    Action = getActionComponentFromString(params.get('Action'));
  }

  const getCMSSections = () => {
    if (Action) {
      return [];
    }

    // These are the sections from the CMS that are associated with this sector.
    return sections
      .filter((s) => !s.is_offline && s.page_version_id === pageVersion.id)
      .map(sectionComponentFromSectionObject)
    ;
  };

  const getMasterSections = () => {
    if (!masterPage) {
      return [];
    }

    return sections
      .filter((s) => !s.is_offline && s.page_version_id === masterPage.published_version_id)
      .map(sectionComponentFromSectionObject)
    ;
  };

  const getActionSections = () => {
    if (!Action || !sector.is_primary) {
      return [];
    }

    return [<Action key="1" />];
  };

  if (sector.master_content_position === 'top') {
    return [...getMasterSections(), ...getActionSections(), ...getCMSSections()];
  } else {
    return [...getActionSections(), ...getCMSSections(), ...getMasterSections()];
  }
}

export default Sector;
