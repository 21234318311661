import { useEffect } from 'react';
import { useAppSelector } from '../rootStore';

const Parallax: React.FC = () => {
  const template = useAppSelector((state) => state.pageData.template);
  const cl = document.documentElement.classList;

  useEffect(() => {
    let bgs: HTMLElement[] = []; // All background elements that need parallax
    let device: string = '';
    const sectorMap = {}; // A map of `${device}_${sectorLabel}` to sector objects
    const wrapperMap = {}; // Map of sector labels to .parallax-wrapper elements

    let lastScrollPos = 0;
    let ticking = false;

    document.addEventListener('render', () => {
      bgs = [...document.querySelectorAll('.device-desktop .parallax-desktop.parallax-background, .device-tablet .parallax-tablet.parallax-background, .device-phone .parallax-phone.parallax-background')] as HTMLElement[];

      if (bgs.length === 0) {
        return;
      }

      if (cl.contains('device-desktop')) {
        device = 'desktop';
      } else if (cl.contains('device-tablet')) {
        device = 'tablet';
      } else if (cl.contains('device-phone')) {
        device = 'phone';
      }

      bgs.forEach((bg) => {
        wrapperMap[bg.dataset.sector as string] = document.querySelector(`.parallax-foreground[data-sector="${bg.dataset.sector}"]`)?.closest('.parallax-wrapper');
      });

      ['desktop', 'tablet', 'phone'].forEach((d) => {
        template[`${d}_layout`]?.forEach((s) => {
          sectorMap[`${d}_${s.label}`] = s;
        });
      });
    });

    const animate = (scrollPos) => {
      if (!bgs || !device) {
        return;
      }

      bgs.forEach((bg) => {
        const sectorLabel = bg.dataset.sector as string;
        const wrapper = wrapperMap[sectorLabel];
        if (!wrapper) {
          return;
        }

        const sector = sectorMap[`${device}_${sectorLabel}`];
        if (!sector) {
          return;
        }

        const speed = 0 - (sector.parallax_speed || 0.5);
        const wrapperOffsetTop = wrapper.offsetTop;
        bg.style.transform = `translateY(${(wrapperOffsetTop - scrollPos) * speed}px)`;

        // EXPERIMENTAL: Setting sector height to 100% so that we can use background images on sections.
        bg.style.height = `calc(100% - ${(wrapperOffsetTop - scrollPos) * speed}px)`;
        // END EXPERIMENTAL
      });
    };

    const scrollHandler = () => {
      lastScrollPos = window.scrollY;

      if (!ticking) {
        window.requestAnimationFrame(() => {
          animate(lastScrollPos);
          ticking = false;
        });

        ticking = true;
      }
    };

    document.addEventListener('scroll', scrollHandler);

    animate(window.scrollY);
  }, []);

  return null;
};

export default Parallax;
