import React, { useEffect, useState } from 'react';
import { FrameBox } from './Sections/ImageGallery/Popup';

const LinkClickListener = () => {
  const [modal, setModal] = useState(false);
  const [url, setUrl] = useState('');

  useEffect(() => {
    document.addEventListener('click', (e) => {
      const a = e.target.closest('a');
      if (!a) {
        return;
      }

      if (a.matches('.showModal')) {
        e.preventDefault();

        const url = new URL(a.href);
        url.searchParams.append('wgpopup', 1);
        setUrl(url.toString());

        setModal(true);
      } else if (a.matches('.toggleVisibility')) {
        const originId = a.dataset.originId;
        if (!originId) {
          return;
        }

        e.preventDefault();
        $(`.origin${originId}`).slideToggle();
      }
    });
  }, []);

  return modal
    ? (
        <FrameBox
          modal={modal}
          url={url}
          toggle={(e) => {
            setModal(e);
            setUrl('');
          }}
        />
      )
    : <></>;
};

export default LinkClickListener;
