import { useEffect } from 'react';
import { useAppSelector } from '../rootStore';

const AddWebVitalsTracking: React.FC = () => {
  const instance = useAppSelector((state) => state.pageData.instance);
  const site = useAppSelector((state) => state.pageData.site);

  useEffect(() => {
    window.addEventListener('load', () => {
      if (document.getElementById('webVitals')) {
        return;
      }

      const script = document.createElement('script');
      script.id = 'webVitals';
      script.src = 'https://cdn.jsdelivr.net/npm/web-vitals@3.5.2/dist/web-vitals.attribution.iife.min.js';
      script.onload = () => {
        (window as any).webVitals.onCLS(sendToServer);
        (window as any).webVitals.onFID(sendToServer);
        (window as any).webVitals.onLCP(sendToServer);
        (window as any).webVitals.onTTFB(sendToServer);
        (window as any).webVitals.onFCP(sendToServer);
        (window as any).webVitals.onINP(sendToServer);
      };

      document.head.appendChild(script);
    });
  }, []);

  const sendToServer = (metric: { name: any; value: any }) => {
    const body = JSON.stringify({
      hit_id: (window as any).wg.hitId,
      metric: metric.name,
      value: metric.value,
    });
    const headers = {
      type: 'application/json',
    };
    const url = `/api/v1/instances/${instance.id}/sites/${site.id}/web_vitals`;
    const blob = new Blob([JSON.stringify(body)], headers);
    if (navigator.sendBeacon) {
      navigator.sendBeacon(url, blob);
    } else {
      fetch(url, {
        body,
        method: 'POST',
        keepalive: true,
        headers: headers,
      });
    }
  };
  return null;
};

export default AddWebVitalsTracking;
