import React, { useEffect, useState } from 'react';
import { getCookie } from '../Utils';
import { Modal, ModalBody, ModalFooter, Button } from 'reactstrap';
import { createStore } from '../rootStore';

const Popup = () => {
  const [modal, setModal] = useState(false);

  const store = createStore();
  const { pageData: { site, page, member } } = store.getState();

  // page popup should be given preference over site popup
  const popupObject = page.popup_page_id ? page : (site.popup_page_id ? site : {});

  const {
    popup_visible_to: popupVisibleTo,
  } = popupObject;

  const {
    frequency = 'every_visit',
    frame = true,
    width = '400',
    trigger = 'delay',
    trigger_value: triggerValue,
  } = (popupObject.popup_options || {});

  let popupShown = false;
  const showPopup = () => {
    if (popupShown) {
      return;
    }
    setModal(true);
    if (frequency === 'once_per_visitor') {
      document.cookie = `popupSeen=1; max-age=${86400 * 365 * 10}; path=/;`;
    } else if (!frequency || frequency === 'every_visit') {
      document.cookie = `popupSeen=1; max-age=86400; path=/;`;
    }
    popupShown = true;
  };

  useEffect(() => {
    if (location.search.match(/wgpopup/)) {
      // We are inside a popup, need to send the height to the parent.
      const setIframeHeight = () => top.postMessage({
        iframeLocation: window.location.href,
        iframeHeight: document.body.clientHeight,
      }, '*');

      setIframeHeight();

      // And run it every so often just in case things inside the iframe get resized.
      setInterval(setIframeHeight, 250);
    }

    const popupSeen = getCookie('popupSeen');

    if (popupSeen) {
      return;
    }

    switch (trigger) {
      case 'delay': {
        const seconds = parseInt(triggerValue, 10);
        setTimeout(showPopup, seconds * 1000);
        break;
      }
      case 'scroll_percentage': {
        const triggerPercentage = parseInt(triggerValue, 10);
        if (triggerPercentage > 0) {
          document.addEventListener('scroll', () => {
            const scrolledPercentage
              = (window.scrollY / document.body.scrollHeight) * 100;
            if (scrolledPercentage > triggerPercentage) {
              showPopup();
            }
          });
        }
        break;
      }
      case 'exit_intent':
        document.addEventListener('mouseout', (e) => {
          if (!e.toElement && !e.relatedTarget) {
            showPopup();
          }
        });
        break;
      default:
        showPopup();
    }
  }, []);

  if (!popupObject.popup_url) {
    return;
  }

  if (popupVisibleTo === 'all_database_members' && !member) {
    return;
  }

  if (popupVisibleTo === 'those_not_on_your_database' && member) {
    return;
  }

  // Don't want to show a popup inside a popup
  if (window != window.top) {
    return;
  }

  return (
    <Modal
      isOpen={modal}
      modalClassName="no-hoist"
      className={frame ? '' : 'no-frame'}
      size="lg"
      centered
      style={{ width: `${width}px`, maxWidth: '80%' }}
      toggle={() => setModal(!modal)}
    >
      <ModalBody>
        <iframe
          id="popup"
          src={`${popupObject.popup_url}?wgpopup`}
          style={{ width: '100%', maxHeight: '70vh' }}
        />
      </ModalBody>
      <ModalFooter>
        <Button onClick={() => setModal(false)}>
          Close
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default Popup;
